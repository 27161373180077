import React, { useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';

import NONE from '../../../../constants';
import UserContext from '../../../../UserContext';

import Dropdown from '../../../../core/Input/Dropdown';
import Questions from '../../../../core/Input/Questions/Questions';
import Button from '../../../../core/Input/Button';
import MultipleSelect from '../../../../core/Input/MultipleSelect';

import Dialog from '../../../../core/Display/Dialog';
import InlineMessage from '../../../../core/Display/InlineMessage';

import {
  getErrorMessage,
  formatQuestions,
} from '../../../../core/Utils/objectUtils';

import { addJournalPeopleItem, doManuAction } from '../../../../utils/networkUtils';

const ASSIGN_REFEREE = 'assign-referee';
const REMOVE_REFEREE = 'remove-referee';

function doAction(manu, action) {
  if (!action) return '';
  return doManuAction(manu, action);
}

function transformAllReferees(referees) {
  return Object.keys(referees).map((key) => {
    const { _id: id, name } = referees[key];
    return { id, name };
  });
}

export function transformManuReferees(referees, manuRefs) {
  const refs = Object.keys(referees).filter((key) => manuRefs.includes(key));
  return refs.map((key) => ({
    id: key,
    name: referees[key].name,
  }));
}

function ActionOptions({
  action = NONE,
  value = undefined,
  setValue,
  manuscript,
  allReferees,
  setAddPersonWindow,
}) {
  switch (action) {
    case ASSIGN_REFEREE:
      return (
        <MultipleSelect
          options={transformAllReferees(allReferees)}
          id="assignReferee"
          label="Select a referee to assign."
          value={value}
          onChange={setValue}
          singleSelect
          addOption={() => { setAddPersonWindow(true); }}
          expandUpwards
        />
      );
    case REMOVE_REFEREE:
      return (
        <MultipleSelect
          options={transformManuReferees(allReferees, manuscript.referees)}
          id="removeReferee"
          label="Select a referee to remove."
          value={value}
          onChange={setValue}
          singleSelect
          expandUpwards
        />
      );
    default: return null;
  }
}
ActionOptions.propTypes = {
  action: propTypes.string,
  value: propTypes.string,
  setValue: propTypes.func.isRequired,
  manuscript: propTypes.instanceOf(Object).isRequired,
  allReferees: propTypes.instanceOf(Object).isRequired,
  setAddPersonWindow: propTypes.func.isRequired,
};

function getActionOptions(column, actions) {
  return [
    { id: NONE, name: 'None' },
    ...Object.keys(column).map((id) => ({ id, name: actions[id] })),
  ].filter(({ id }) => id !== 'editor-move');
}

function AddPersonFormWindow({
  peopleQuestions,
  addPersonWindow,
  setAddPersonWindow,
  refreshManuscripts,
  refreshReferees,
  manuscript,
  closeDetails,
}) {
  if (!addPersonWindow || !peopleQuestions) {
    return (
      <div className="add-person-form-window empty" />
    );
  }
  const { _id: manuscriptId } = manuscript;
  const [questions, setQuestions] = useState(formatQuestions(peopleQuestions));
  const [error, setError] = useState();
  const { userID } = useContext(UserContext);

  const actionDict = {
    action: ASSIGN_REFEREE,
    Editor: userID,
  };

  const closeWindow = () => setAddPersonWindow(false);
  const onSubmit = () => {
    const person = {};
    Object.keys(questions).forEach((key) => {
      person[key] = questions[key].value;
    });
    addJournalPeopleItem(person)
      .then((record) => {
        actionDict.referee = record.ret;
        doAction(manuscriptId, actionDict)
          .then(() => {
            refreshReferees();
            refreshManuscripts();
            closeWindow();
            closeDetails();
          })
          .catch((err) => {
            setError(err);
          });
      })
      .catch((err) => {
        setError(err);
      });
  };
  const responseSetter = (field, value) => {
    setQuestions({
      ...questions,
      [field]: { ...questions[field], value },
    });
  };
  questions.roles.value = ['RE'];
  return (
    <Dialog
      title="Add a person"
      closeFn={closeWindow}
      className="manuscript-wrapper"
    >
      <Questions
        questions={questions}
        onSubmit={() => { onSubmit(); }}
        responseSetter={responseSetter}
        horizontal
      />
      {error && <p>Server error.</p>}
    </Dialog>
  );
}
AddPersonFormWindow.propTypes = {
  peopleQuestions: propTypes.instanceOf(Object).isRequired,
  addPersonWindow: propTypes.bool.isRequired,
  setAddPersonWindow: propTypes.func.isRequired,
  refreshManuscripts: propTypes.func.isRequired,
  refreshReferees: propTypes.func.isRequired,
  manuscript: propTypes.instanceOf(Object).isRequired,
  closeDetails: propTypes.func.isRequired,
};

export default function ManuscriptActions({
  manuscript,
  column,
  refreshManuscripts,
  refreshReferees,
  actions,
  allReferees,
  closeWindow,
  peopleQuestions,
}) {
  const { _id: manuscriptId } = manuscript;
  const [actionToDo, setActionToDo] = useState('');
  const [action, setAction] = useState(NONE);
  const [actionValue, setActionValue] = useState();
  const [addPersonWindow, setAddPersonWindow] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const handleError = (err) => { setError(getErrorMessage(err)); };
  const dismissError = () => { setError(); };

  function useAction(manu) {
    const [state, setState] = useState('');
    const { userID } = useContext(UserContext);

    useEffect(() => {
      if (actionToDo) {
        const actionDict = {
          action: actionToDo,
          Editor: userID,
        };
        if ([ASSIGN_REFEREE, REMOVE_REFEREE].includes(actionToDo)) {
          [actionDict.referee] = actionValue;
        }
        doAction(manu, actionDict)
          .then((data) => {
            setState(data.newState);
            setActionToDo('');
            setSuccess(true);
          })
          .catch(handleError);
      }
    }, [actionToDo]);

    return state;
  }

  const actionNeedsValue = [
    ASSIGN_REFEREE,
    REMOVE_REFEREE,
  ].includes(action);
  useAction(manuscriptId);
  return (
    <div>
      <div className="manuscript-actions-bar">
        <Dropdown
          id="manuscript-action-select"
          label="Select action"
          options={getActionOptions(column, actions)}
          onChange={({ target }) => { setActionValue(); setAction(target.value); }}
          defaultValue={NONE}
        />
        <ActionOptions
          action={action}
          value={actionValue}
          setValue={setActionValue}
          manuscript={manuscript}
          allReferees={allReferees}
          setAddPersonWindow={setAddPersonWindow}
        />
        {action !== NONE && (
          <Button
            disabled={actionNeedsValue && !actionValue}
            type="button"
            onClick={() => { setActionToDo(action); }}
            classes={['align-right']}
          >
            Submit
          </Button>
        )}
      </div>
      {error && <InlineMessage type="error" text={error} closeFunction={dismissError} />}
      <AddPersonFormWindow
        peopleQuestions={peopleQuestions}
        addPersonWindow={addPersonWindow}
        setAddPersonWindow={setAddPersonWindow}
        refreshManuscripts={refreshManuscripts}
        refreshReferees={refreshReferees}
        manuscript={manuscript}
        closeDetails={closeWindow}
      />
      {success && (
        <Dialog
          title="Success!"
          closeFn={() => {
            window.location.reload();
          }}
        />
      )}
    </div>
  );
}
ManuscriptActions.propTypes = {
  manuscript: propTypes.instanceOf(Object).isRequired,
  column: propTypes.instanceOf(Object).isRequired,
  refreshManuscripts: propTypes.func.isRequired,
  refreshReferees: propTypes.func.isRequired,
  actions: propTypes.instanceOf(Object).isRequired,
  allReferees: propTypes.instanceOf(Object).isRequired,
  closeWindow: propTypes.func.isRequired,
  peopleQuestions: propTypes.instanceOf(Object).isRequired,
};
