const name = 'Boaz Kaufman';
const role = 'Front-End Developer';
const text = `Boaz Kaufman graduated Magna Cum Laude from Hunter College
with a B.A. in Computer Science. Boaz has interned with the cybersecurity team
of the NYC Department of Education and has a year of experience teaching computer science
in a public high school.`;

const boazKaufman = { name, role, text };

export default boazKaufman;
