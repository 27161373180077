import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import propTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';

import { getPath } from '../../../../constants';
import {
  getAddJournalPeopleItemForm,
  getJournalDashColumns,
  getJournalManuscriptsMap,
  getJournalPeopleRead,
  getJournalStatesRead,
  getManuActions,
} from '../../../../utils/networkUtils';
import { getErrorMessage } from '../../../../core/Utils/objectUtils';
import InlineMessage from '../../../../core/Display/InlineMessage';
import LoadingAnimation from '../../../../core/Display/LoadingAnimation';
import ContentBox from '../../../../core/Display/ContentBox';
import UserContext from '../../../../UserContext';
import PageTitle from '../../../../core/Display/PageTitle';
import Button from '../../../../core/Input/Button';

import SecurityContext from '../../../SecurityContext';
import ProgressIndicator from '../../../Common/ProgressIndicator';

import './Dashboard.css';
import ManuscriptDetails from './ManuscriptDetails';
import Column from './Column';
import FilterPanel from './FilterPanel';
import SearchAndFilterButtons from './SearchAndFilterButtons';
import ZoomControl from './ZoomControl';

const INITIAL_FILTERS = {
  search: '',
  statuses: [],
  authors: [],
  reviewers: [],
};

function ErrorMessages({ list = [] }) {
  if (!Array.isArray(list)) {
    return (
      <InlineMessage
        text={`ErrorMessages: list is not an array. ${JSON.stringify(list)}`}
        type="error"
      />
    );
  }
  if (!list.length) return null;
  return list.map((message) => <InlineMessage key={message} text={message} type="error" />);
}
ErrorMessages.propTypes = {
  list: propTypes.instanceOf(Array),
};

export default function Dashboard() {
  const [manuscripts, setManuscripts] = useState(undefined);
  const [viewedManuscript, setViewedManuscript] = useState(undefined);
  const [authorized, setAuthorized] = useState(undefined);

  const [zoomLevel, setZoomLevel] = useState(100);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [columns, setColumns] = useState(undefined);
  const [columnOrder, setColumnOrder] = useState(undefined);
  const [statesObj, setStatesObj] = useState(undefined);
  const [actions, setActions] = useState(undefined);
  const [referees, setReferees] = useState(undefined);
  const [peopleQuestions, setPeopleQuestions] = useState(undefined);
  const [errorMessages, setErrorMessages] = useState([]);

  const { receivedURLs } = useContext(UserContext);
  const { page, setPage, isValidUser } = useContext(SecurityContext);

  const showError = (error, label) => {
    const message = label
      ? `${label}: ${getErrorMessage(error)}`
      : getErrorMessage(error);
    if (errorMessages.includes(message)) return;
    errorMessages.push(message);
    setErrorMessages([...errorMessages]);
  };

  const refreshManuscripts = () => {
    getJournalManuscriptsMap()
      .then((data) => {
        setManuscripts(Object.values(data));
      })
      .catch((error) => { showError(error, 'Manuscripts'); });
  };

  const refreshReferees = () => {
    getJournalPeopleRead()
      .then(setReferees)
      .catch((error) => { showError(error, 'People'); });
  };

  useEffect(() => {
    if (!receivedURLs) return;

    if (page !== 'journal') {
      setPage('journal');
      return;
    }

    isValidUser('create', 'journal').then(setAuthorized).catch(() => { setAuthorized(false); });
  }, [receivedURLs, page]);

  useEffect(() => {
    if (!receivedURLs) return;

    refreshManuscripts();

    getJournalDashColumns()
      .then((data) => {
        if (!data.map) {
          showError(
            "Data returned by getJournalDashColumns has no property 'map'",
            'Dashboard Columns',
          );
        }
        setColumns(data.map);

        if (!data.order) {
          showError(
            "Data returned by getJournalDashColumns has no property 'order'",
            'Dashboard Columns',
          );
        }
        setColumnOrder(data.order);
      })
      .catch((error) => { showError(error, 'Dashboard Columns'); });

    getJournalStatesRead()
      .then(setStatesObj)
      .catch((error) => { showError(error, 'States'); });

    getManuActions()
      .then(setActions)
      .catch((error) => { showError(error, 'Actions'); });

    refreshReferees();

    getAddJournalPeopleItemForm()
      .then(setPeopleQuestions)
      .catch((error) => { showError(error, 'People Form'); });
  }, [receivedURLs]);

  if (authorized === undefined) {
    return (
      <div style={{ width: 'fit-content', margin: 'auto' }}>
        <LoadingAnimation />
        <br />
        <span>Checking permissions...</span>
      </div>
    );
  }
  if (authorized === false) return <Navigate to={getPath('SFA.JOURNALHOME')} />;
  const PROGRESS = [
    ['Fetching manuscripts', manuscripts !== undefined],
    ['Fetching column information', columns !== undefined],
    ['Fetching column order', columnOrder !== undefined],
    ['Fetching state information', statesObj !== undefined],
    ['Fetching manuscript actions', actions !== undefined],
    ['Fetching people', referees !== undefined],
  ];
  const PROGRESS_LABELS = PROGRESS.map((step) => step[0]);
  const PROGRESS_STATUS = PROGRESS.map((step) => step[1]);
  const FINISHED_LOADING = PROGRESS_STATUS
    .filter((status) => status === true)
    .length === PROGRESS.length;
  if (!FINISHED_LOADING) {
    return (
      <ContentBox>
        <ErrorMessages list={errorMessages} />
        <ProgressIndicator steps={PROGRESS_LABELS} progress={PROGRESS_STATUS} />
      </ContentBox>
    );
  }
  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-header">
        <PageTitle text="Dashboard" />
        {false && (
          <SearchAndFilterButtons
            filters={filters}
            setFilters={setFilters}
            filterPanelOpen={filterPanelOpen}
            setFilterPanelOpen={setFilterPanelOpen}
          />
        )}
      </div>
      {false && (
        <FilterPanel
          filters={filters}
          setFilters={setFilters}
          filterPanelOpen={filterPanelOpen}
        />
      )}
      <ErrorMessages list={errorMessages} />
      <div className="dashboard-columns-wrapper">
        <ZoomControl zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} />
        <DragDropContext onDragEnd={() => {}}>
          {columnOrder.map((key) => (
            <Column
              key={key}
              manuscripts={manuscripts}
              status={key}
              header={columns[key] && columns[key].text}
              setViewedManuscript={setViewedManuscript}
              filters={filters}
              zoomLevel={zoomLevel}
              allReferees={referees}
            />
          ))}
        </DragDropContext>
      </div>
      <ManuscriptDetails
        manuscript={viewedManuscript}
        setViewedManuscript={setViewedManuscript}
        manuscripts={manuscripts}
        setManuscripts={setManuscripts}
        column={viewedManuscript && columns[viewedManuscript.state]}
        refreshManuscripts={refreshManuscripts}
        refreshReferees={refreshReferees}
        statesObj={statesObj}
        actions={actions}
        allReferees={referees}
        peopleQuestions={peopleQuestions}
        setPeopleQuestions={setPeopleQuestions}
      />
      <div className="people-link">
        <Link to="./people">
          <Button color="secondary">
            People
          </Button>
        </Link>
      </div>
    </div>
  );
}
